import React from 'react';
import PropTypes from 'prop-types';
import styles from './videoPlayer.module.scss';

const VideoPlayer = ({ videoIframe }) => (
  <div className={styles.videoWrapper}>
    <div dangerouslySetInnerHTML={{ __html: videoIframe }} />
  </div>
);

VideoPlayer.propTypes = {
  videoIframe: PropTypes.string.isRequired,
};

export default VideoPlayer;
