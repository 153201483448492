/* eslint-disable react/forbid-prop-types */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import VideoPlayer from '../components/VideoPlayer';
import SEO from '../components/SEO';
import BlogSidebar from '../components/BlogSidebar';
import BlogPostCategories from '../components/BlogPostCategories';
import {
  cleanTitle,
  cleanExcerpt,
  formatDate,
  cleanContent,
  getFeaturedImage,
  getVideoIFrame,
} from '../utils/postUtils';

import styles from './blogPost.module.scss';

const BlogPost = ({ data, pageContext }) => {
  const backgroundHeaderImage =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108997/bg-blog_gewlx8.jpg';

  const { title, content, date, categories, slug, previous, next } = pageContext;

  const featuredImage =
    data?.allAllPostsJson?.edges[0]?.node?.fields?.localFeaturedImage?.childImageSharp?.fixed;
  const scrubbedTitle = cleanTitle(title);
  const scrubbedExcerpt = cleanExcerpt(content);
  const backupFeaturedImage = getFeaturedImage(content);
  const scrubbedContent = cleanContent(content);
  const videoIFrame = getVideoIFrame(content);

  return (
    <Layout>
      <SEO
        title={`${scrubbedTitle} | Mindset Family Therapy`}
        description={scrubbedExcerpt}
        image={backupFeaturedImage}
        pathname={`/blog/${slug}`}
        article
      />
      <PageHeader pageCategory="Mindset" pageName="Blog" headerImage={backgroundHeaderImage} />
      <div className={styles.blogWrapper}>
        <div className={styles.postWrapper}>
          <div className={styles.titleContainer}>
            <h1 className={styles.postTitle} dangerouslySetInnerHTML={{ __html: scrubbedTitle }} />
            <div className={styles.date}>{formatDate(date)}</div>
          </div>

          <BlogPostCategories categories={categories} />

          <div>
            {featuredImage && (
              <div className={styles.postImage}>
                <Img fluid={featuredImage} />
              </div>
            )}

            {!featuredImage && backupFeaturedImage && (
              <img src={backupFeaturedImage} className={styles.postImage} alt="Mindset Blog Post" />
            )}

            <div className={styles.postContent}>
              <div dangerouslySetInnerHTML={{ __html: scrubbedContent }} />
              {videoIFrame && (
                <div className={styles.videoContainer}>
                  <div className={styles.video}>
                    <VideoPlayer videoIframe={videoIFrame} />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.postNavWrapper}>
              {next && (
                <Link to={`/blog/${next}`}>
                  <div className={styles.postNavLink}>&larr;</div>
                </Link>
              )}
              {previous && (
                <Link to={`/blog/${previous}`}>
                  <div className={styles.postNavLink}>&rarr;</div>
                </Link>
              )}
            </div>
          </div>
        </div>
        <BlogSidebar />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    allAllPostsJson(filter: { id: { eq: $id } }) {
      edges {
        node {
          fields {
            localFeaturedImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default BlogPost;
