import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from './postCategories.module.scss';

const PostCategories = ({ categories }) => {
  return (
    <div className={styles.postCategoriesWrapper}>
      {_.map(categories, ({ id, name, slug }) => (
        <Link key={id} to={`/blog/category/${slug}`} className={styles.category}>
          <div>{_.toUpper(name)}</div>
        </Link>
      ))}
    </div>
  );
};

PostCategories.propTypes = {
  categories: PropTypes.array.isRequired,
};

export default PostCategories;
